import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { updateUserCartData, addUserCartData } from '../Api';
import { displayPartsToString } from 'typescript';

export const QuantityForm = (props) => {
    const { control, register, setValue, getValues, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        await setLoading(true);
        let qty = getValues(line.ReferenceID + "qty");
        let mutationData = {"Qty": qty, "ReferenceID": props.line.ReferenceID}
        console.log("mutationData: ", mutationData);

        if (props.type === "update") {
            await updateMutation.mutate(mutationData);
            setLoading(false);
        } else {
            await creationMutation.mutate(mutationData);
            setLoading(false);
        }
    }

    const queryClient = useQueryClient()

    const updateMutation = useMutation(updateCartData => updateUserCartData(updateCartData), {
        onSuccess: async updateCartCount => {
            await queryClient.invalidateQueries('cartDataList');
            await queryClient.invalidateQueries('cartDataSubtotal');
        },
    });

    const creationMutation = useMutation(updateCartData => addUserCartData(updateCartData), {
        onSuccess: async updateCartCount => {
            await queryClient.invalidateQueries('cartData');
        },
    });

    let line = props.line;

    return (
        
        <form className="col-2" onSubmit={handleSubmit(onSubmit)} style={{"display": props.type === "update" ? "block" : "contents"}}>
            <Controller
                name={line.ReferenceID + "qty"}
                control={control}
                defaultValue={parseInt(line.Qty)}
                field={line.Qty}
                render={({ field }) => <InputNumber showButtons style={{ 'paddingBottom': '.5rem', 'textAlign': 'center' }} inputStyle={{ 'width': '100%' }} inputId={line.ReferenceID + 'inputNumberQty'} value={parseInt(line.Qty)} onValueChange={(event) => setValue(line.ReferenceID + "qty", event.value, { shouldValidate: true })} mode="decimal" min={line.minOrderQty && parseInt(line.minOrderQty) !== 0 ? parseInt(line.minOrderQty) : 1} max={100} />}
            />
            {props.type === "update" ? <Button label="Update" className="button-link" type="submit" loading={loading}></Button> : <Button label="Add to Cart" loading={loading}></Button>}

        </form>
    );
}
