import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useParams, useLocation } from 'react-router-dom';
import { getUserInvoiceDetails, getUserOrderDetails } from '../Api';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useQuery } from 'react-query';
import LoadingScreen from '../component/loadingScreen';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { useAuth } from '../lib/auth';

export const OrderView = (props) => {
    
    const [orderData, setOrderData] = useState({});
    const [orderLineData, setOrderLineData] = useState({});

    const { id } = useParams();

    const { user } = useAuth();

    const { isLoading, error, data, isFetching }  = useQuery(['orderData', id], () => getUserOrderDetails(id));

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const priceBodyTemplate = (data, props) => {
        const format = formatCurrency(data[props.field]);
        return '$' + format.replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
    }

    if (isFetching) return <LoadingScreen />;

    if (error) return "Error";

    const ItemCard = (props) => {
        let data = props.data;
        
        return (
            <>
                    <div className="grid">
                        <div className="col-12 md:col-3">
                            <img className="" src={data.Product.ImageUrl.replace('.png', '_thumbnail250.png')} alt={data.Product.FullName ? data.Product.FullName : "image"}></img>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="flex text-xl"><p>{data.Product.FullName}</p></div>
                            <div className="flex"><p className="font-semibold">Quantity: </p><p>{data.Qty}</p></div>
                            <div className="flex"><p>{data.Product.ItemDesc}</p></div>
                        </div>
                        <div className="col-12 md:col-3">
                            <div className="flex text-2xl align-content-center"><p>${data.LineSubtotal}</p></div>
                        </div>
                    </div>
                <Divider/>
            </>

        )
    }

    const cardHeader = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="flex align-content-center"><h4 className='flex-none'>Order #{data.order.OrderNumber}</h4><p className="text-right flex-grow-1">Status: {data.order.OrderStatus}</p></div>
                </div>
            </div>
        );
    }

    const cardFooter = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-3">
                    <div className="flex text-xl"><p>Billing address</p></div>
                    <div className="flex">
                        <p>
                            {data.order.Address}<br/>
                            {data.order.AddressAdditional? data.order.AddressAdditional + '<br/>': ""}
                            {data.order.City}, {data.order.State}<br/>
                            {data.order.ZipCode}
                        </p>
                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <div className="flex text-xl"><p>Shipment information</p></div>
                    <div className="flex"><p>{data.order.ShippingEstimateDate}</p></div>
                </div>
                
                <div className="col-12 md:col-3" >
                    <div className="flex"><p>Subtotal: </p><p className="text-right" style={{"width":"100%"}}>${data.order.Subtotal}</p></div>
                    <div className="flex"><p>Shipping: </p><p className="text-right" style={{"width":"100%"}}>${data.order.ShippingCost}</p></div>
                    <div className="flex"><p>Tax: </p><p className="text-right" style={{"width":"100%"}}>${data.order.TaxAmount}</p></div>
                    <div className="flex"><p className="text-bold">Total: </p><p className="text-right" style={{"width":"100%"}}>${data.order.GrandTotal}</p></div>
                </div>
            </div>
        );
    }

    return (
        <div className="grid">
            <div className="col-12">
                <Card title={"Status: " + data.order.OrderStatus} footer={cardFooter}>
                {isLoading? 
                    <LoadingScreen />:
                    data.orderLine.map((dataLine) => (
                            <ItemCard data={dataLine} key={dataLine.id} />
                    ))
                }
                </Card>
            </div>
        </div>
        );
}
