import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { getUserInvoiceDetails } from '../Api';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const CreateOrder = (props) => {

    return (
           <div className="grid">
                <div className="col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <div className="invoice-header">
                                    <div className="invoice-company">
                                        <img id="invoice-logo" className="logo-image" src={props.logoUrl} alt="medvantage-logo" />
                                        <div className="company-name">MedVantage</div>
                                        <div>230 W Passaic St UNIT 4, Maywood</div>
                                        <div>NJ 07607, U.S.A.</div>
                                    </div>
                                </div>
                                <div className="invoice-to">
                                    <div className="bill-to">BILL TO</div>
                                    <div className="invoice-to-info">
                                    </div>
                                </div>
                                <div className="invoice-items">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
