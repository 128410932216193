import React, { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { deleteUserCartItem, getUserCart, getUserCartSubtotal, getUserProducts, updateUserCartData } from '../Api';
import { Button } from 'primereact/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LoadingScreen from '../component/loadingScreen';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { useForm } from 'react-hook-form';
import { QuantityForm } from './QuantityForm';
import { useRouter } from '../component/useRouter';


export const MyCart = () => {

    const { isLoading, error, data, isFetching } = useQuery("cartDataList", getUserCart);

    const { isLoading: isLoadingSubtotal, error: errorSubtotal, data: dataSubtotal, isFetching: isFetchingSubtotal } = useQuery("cartDataSubtotal", getUserCartSubtotal);
    
    const queryClient = useQueryClient()

    const mutation = useMutation(deleteCartData => deleteUserCartItem(deleteCartData), {
        onSuccess: async updateCartCount => {
            await queryClient.invalidateQueries('cartDataList');
            await queryClient.invalidateQueries('cartDataSubtotal');
            await queryClient.invalidateQueries('cartData');
        },
    });

    const router = useRouter();

    const deleteCartItem = async (event, data) => {
        event.preventDefault();
        console.log(data);
        mutation.mutate(data);
        
    }

    const recentProductsHeader = (
        <div className="card-header">
            <h4>Cart</h4>
        </div>
    );
    
    if (isLoading) return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    < LoadingScreen />
                </div>
            </div>
        </div>
    );

    if (isFetching) return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    < LoadingScreen />
                </div>
            </div>
        </div>
    );

    if (error) return "Error";

    const renderCards = (data) => {
        return (
            <div className="col-12">
                {data.map((line) => 
                    <div key={line.ReferenceID}>
                        <div className="flex" style={{position: 'relative'}}>
                            <div className="col-2"><img className="product-img-thumbnail" src={line.Product.ImageUrl.replace('.png', '_thumbnail250.png')} alt={'product'}></img></div>
                            <div className="col-10">
                                <div className="flex">
                                    <div className="col-7">
                                        <div className="product-name">{line.Product.FullName}</div>
                                        <div>{line.Product.ItemDesc}</div>
                                    </div>
                                    <QuantityForm line={line} type={"update"}></QuantityForm>
                                    <div className="col-3" style={{'paddingBottom': '.5rem', 'textAlign': '-webkit-center'}}>
                                        <h3>${line.LineSubtotal}</h3>
                                        <Button label="Remove" className="button-link" onClick={ (event) => deleteCartItem(event, line.ReferenceID)}></Button>
                                    </div>
                                </div>
                                <div>
                                    <div style={{position: 'absolute', bottom: 0}}><i className={"pi pi-clock product-shipping-icon"}></i><span className="shipping-info">{line.deliveryEstimate ? 'Ships ' + line.deliveryEstimate : "Shipping estimate unavailable"}</span></div>
                                </div>
                            </div>
                        </div>
                        <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="d-flexflex-column">
            <Card>
                <div className="card-header">
                    <h3>My Cart</h3>
                </div>
                <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                {data && data.length !== 0 ? renderCards(data) : <div><h4>Your cart is empty</h4><Button label="View products" className="button-link" onClick={(e) => router.push("/")}/></div>}
            </Card>
            {data && data.length !== 0 ? 
                <div className="">
                        <div className="col"></div>
                        <Card>
                            <div className="card-header">
                                <h3>Order Summary</h3>
                            </div>
                            <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                            { isFetchingSubtotal? <h3>Loading...</h3> :
                                <div> 
                                    <div className="d-flexflex-md-col">
                                        <div><h4>Subtotal: ${dataSubtotal}</h4></div>
                                    </div>
                                    <Button label="Checkout" onClick={(e) => router.push("/checkout")}/>
                                </div>
                            }
                        </Card>
                </div>
            : <div></div> }
        </div>
    );
}