import React, { useState, useRef} from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../lib/auth';
import { useHistory } from "react-router-dom";
import { Toast } from 'primereact/toast';


export const Login = () => {
    const { control, handleSubmit } = useForm();
    const { login } = useAuth();
    const toast = useRef(null);

    const onSubmit = async (data) => {
        try {
            await login(data);
            window.location.reload();
        } catch(e) {
            if (e.statusCode === 401 ) {
                toast.current.show({severity:'error', summary: 'Authentication Failed', detail:"Unable to login with the provided username and password.", life: 3000});
            } else {
                toast.current.show({severity:'error', summary: 'Authentication Failed', detail:"Unable to contact server.", life: 3000});
            }
            console.log(e);
        }
    };

    const history = useHistory();
    

    const onClickReset = () => {
        history.push('/reset/password');
    }

    
    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <img src="assets/layout/images/logo-dark.svg" className="logo" alt="logo" />

                    <div className="login-form">
                        <h5 className="text-center">Login</h5>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="field">
                                <span className="float-label">
                                    <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <InputText {...field} placeholder="Email"/>}
                                    />
                                </span>
                            </div>
                            <div className="field">
                                <span className="float-label">
                                    <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <Password {...field} feedback={false} placeholder="Password"/>}
                                    />
                                </span>
                            </div>
                            <Button label="CONTINUE" type="submit"></Button>
                        </form>
                    </div>
                    <p>Having a problem logging in? <Button onClick={onClickReset} className="p-button-link" style={{padding: "0", paddingBottom: ".2rem"}}>Click here</Button> to reset your password.</p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">
                        <h1>Access to your</h1>
                        <h1>MedVantage</h1>
                        <h1>Account</h1>
                    </div>
                    <div className="image-footer">
                    </div>
                </div>
            </div>
            <Toast ref={toast} />
        </div>
    );
}
