import { Login } from "./screens/Login";
import React from 'react';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/fluent-light/theme.css';
import './App.scss'
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './lib/auth';
import { useAuth } from "./lib/auth";
import BaseCode  from "./BaseCode";
import { ReactQueryDevtools } from 'react-query/devtools';
import {Redirect} from 'react-router-dom';

const queryClient = new QueryClient();

export default function App () {
        return (
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <CheckAuth />
                </AuthProvider>
                <ReactQueryDevtools/>
            </QueryClientProvider>
          );
}

function CheckAuth() {
    const { user } = useAuth();
    if (!user) {
        <Redirect to="/" />
        return (<Login />);
    } else {
        return (<BaseCode />);
    }
}