import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { getUserInvoices } from '../Api';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { RecentProducts } from './RecentProducts';
import { useQuery } from 'react-query';
import LoadingScreen from '../component/loadingScreen';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';

export const RecentOrders = (props) => {

    let data = props.isLoading? "{}" : props.data;
    console.log(data);

    const buttonInvoiceDetail = (data) => {
        let addressLink;
        if (data.OrderStatus === 'Invoiced') {
            addressLink = "/invoices/view/" + data.TxnID
        } else {
            addressLink = "/orders/view/" + data.id
        }

        return (
            <NavLink activeClassName="active-route" to={addressLink} exact>
                <Button>View</Button>
            </NavLink>
        );
    }

    const bodyTemplate = (data, props) => {
        return (
            <>
                {data[props.field]}
            </>
        );
    };

    const dateTemplate = (data, props) => {
        let date;
        if (data[props.field]) {
            date = moment(data[props.field]).format("MMMM Do, YYYY");
        }
        return(
            <>
                {date}
            </>
        )
    };

    const subtotalTemplate = (data, props) => {
        let amount = data[props.field];
        return(
            <>
                ${Number(amount).toFixed(2)}
            </>
        )
    };

    return (
        <div className="card">
            <div className="card-header">
                <h4>{props.title? props.title : "Orders"}</h4>
                <p>Total: {data ? data === "{}" ? 0 : data.length : 0}</p>
            </div>

            {props.isLoading ? <ProgressSpinner /> : 
                data !== "{}" && data.length !== null && 
                <DataTable value={data} paginator rows={props.rows? props.rows : 12} autolayout={true} className="datatable-products">
                    <Column field="OrderNumberText" header="Reference Number" sortable filter={true} body={bodyTemplate}></Column>
                    <Column field="date" header="Date" sortable body={dateTemplate}></Column>
                    <Column field="GrandTotal" header="Subtotal" body={subtotalTemplate} sortable ></Column>
                    <Column field="OrderStatus" header="Status" body={bodyTemplate} sortable ></Column>
                    <Column bodyStyle={{ textAlign: 'center', justifyContent: 'center' }} body={buttonInvoiceDetail}></Column>
                </DataTable>
            }
        </div>
    );
}
