import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { postMedvantageApi } from '../../Api';
import { Messages } from 'primereact/messages';

export const ResetPassword = (props) => {

    const defaultValues = {
        email: ''
    }

    const [formData, setFormData] = useState({});
    const [showErrorMessage, setShowErrorMessage] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const messages = useRef(null);


    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const  onSubmit = async (data) => {
        messages.current.clear();
        setIsLoading(true);
        setFormData(data);
        try { 
            let postData = {
                email: data.email
            };
            await postMedvantageApi('user/reset/request', postData);
            
        } catch (error) {}


        reset();
        setIsLoading(false);
        messages.current.show([
            { severity: 'info', detail: 'A password reset request has been sent to the email address provided.', sticky: true }
         ]);
    };

    const cardHeader = () => {
        return (
            <div className='flex justify-content-center' style={{"marginTop":"2rem"}}>
                <h5 className='text-center'>Request password reset</h5>
            </div>
        )
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <div className='form-container'>
            <div className="flex align-items-center justify-content-center" style={{height:"100vh"}}>
                    <Card style={{"minWidth": "400px"}} header={cardHeader}>
                        <Messages ref={messages} />
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid justify-items-center">
                            
                            <div className="field">
                                <span className="p-float-label p-input-icon-right">
                                    <i className="pi pi-envelope" />
                                    <Controller name="email" control={control}
                                        rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                        render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>Email*</label>
                                </span>
                                {getFormErrorMessage('email')}
                            </div>
                            <Button type="submit" label="Submit" loading={isLoading} className="mt-2" />
                        </form>
                    </Card>
            </div>
        </div>
    );
}
