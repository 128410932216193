import React, {  useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOrderInformation, medvantageApi, postMedvantageApi, updateUserOrder } from '../Api';
import { ProgressSpinner } from 'primereact/progressspinner';

export const BillingForm = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [submitIP, setSubmitIP] = useState(false);

    const user = props.user;

    console.log('from bf', user);

    let defaultValues = {
        contactName: user.currentOrderName ? user.currentOrderName : "",
        notes: user.currentOrderPO ? user.currentOrderPO : "",
        email: user.currentOrderEmail ? user.currentOrderEmail : ""
    }

    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

    const queryClient = useQueryClient()

    const updateMutation = useMutation(updateUserOrder => postMedvantageApi('user/update/currentOrder', updateUserOrder), {
        onSuccess: async updateUserOrderSuccess => {
            defaultValues = formData;
            setSubmitIP(false);
        },
    });

    
    const onSubmit = async (data, event) => {
        event.preventDefault();
        setSubmitIP(true);
        console.log(data);
        setFormData(data);
        setShowMessage(true);
        let jsonData = {
            currentOrderName: data.contactName,
            currentOrderEmail: data.email,
            currentOrderPO: data.notes ? data.notes : ""
        }
        await updateMutation.mutate(jsonData);
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="error">{errors[name].message}</small>
    };

    return (
                <form onSubmit={handleSubmit(onSubmit)} className="" id="orderInformation">
                    <div className="field">
                        <label htmlFor="contactName" className={classNames({ 'p-error': errors.address })}>Contact Name*</label>
                        < Controller name="contactName" control={control} rules={{ required: 'Billing contact name is required.' }} render={({ field, fieldState }) => (
                            < InputText id={field.contactName} {...field} autoFocus className={"inputfield w-full " +  classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('contactName')}
                    </div>
                    <div className="field">
                        <label htmlFor="email" className={classNames({ 'p-error': errors.address })}>Contact Email*</label>
                        <span className="input-icon-right">
                            < Controller name="email" control={control}
                                rules={{ required: 'Billing contact email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                render={({ field, fieldState }) => (
                                    < InputText id={field.name} {...field} className={"inputfield w-full " + classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                        </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="field">
                        <label htmlFor="notes">PO #</label>
                        < Controller name="notes" control={control}
                            render={({ field, fieldState }) => (
                                < InputText id={field.notes} className={"inputfield w-full"}  {...field} />
                        )} />
                    </div>
                    <div className="grid">
                        <div className="col-2 col-offset-10 p-fluid">
                            < Button type="submit" label="Save" loading={submitIP} className="mt-2" />
                        </div>
                    </div>
                </form>
    );
}
        