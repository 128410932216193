import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { getUserInvoiceDetails } from '../Api';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useQuery } from 'react-query';
import LoadingScreen from '../component/loadingScreen';

export const Invoice = (props) => {
    
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceLineData, setInvoiceLineData] = useState({});

    const { id } = useParams();

    const { isLoading, error, data, isFetching }  = useQuery(['invoiceLineData', id], () => getUserInvoiceDetails(id))

    console.log(data);
    
    const print = () => {
        window.print();
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const priceBodyTemplate = (data, props) => {
        const format = formatCurrency(data[props.field]);
        return '$' + format.replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
    }

    if (isFetching) return <LoadingScreen />;

    if (error) return "Error";
    return (
        <div>
            <Button type="button" label="Print" icon="pi pi-print" onClick={print} style={{ display: 'block', marginBottom: '20px', marginLeft: '6px' }}></Button>

            <div className="grid">
                <div className="col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <div className="invoice-header">
                                    <div className="invoice-company">
                                        <img id="invoice-logo" className="logo-image" src={props.logoUrl} alt="medvantage-logo" />
                                        <div className="company-name">MedVantage</div>
                                        <div>230 W Passaic St UNIT 4, Maywood</div>
                                        <div>NJ 07607, U.S.A.</div>
                                    </div>
                                    <div>
                                        <div className="invoice-title">INVOICE</div>
                                        <div className="invoice-details">
                                            <div className="invoice-label">DATE</div>
                                            <div className="invoice-value">{moment(data.invoice.TxnDate).format('MMM Do, YYYY')}</div>

                                            <div className="invoice-label">INVOICE #</div>
                                            <div className="invoice-value">{data.invoice.TxnNumber}</div>

                                            <div className="invoice-label">CUSTOMER ID</div>
                                            <div className="invoice-value">C1613</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-to">
                                    <div className="bill-to">BILL TO</div>
                                    <div className="invoice-to-info">
                                        <div>{data.invoice.BillAddressAddr1}</div>
                                        <div>{data.invoice.BillAddressCity}, {data.invoice.BillAddressState} {data.invoice.BillAddressPostalCode}</div>
                                    </div>
                                </div>
                                <div className="invoice-items">
                                {isLoading? <LoadingScreen /> :
                                <DataTable value={data.items} autolayout={true}>
                                    <Column field="InvoiceLineItemRefFullName" header="Item"></Column>
                                    <Column field="InvoiceLineDesc" header="Description" styyle={"width:300px"}></Column>
                                    <Column field="InvoiceLineRate" header="Unit Price"></Column>
                                    <Column field="InvoiceLineQuantity" header="Quantity"></Column>
                                    <Column field="InvoiceLineAmount" header="Line Total" body={priceBodyTemplate}></Column>
                                </DataTable>} 
                                </div>
                                <div className="invoice-summary">
                                    <div className="invoice-notes">
                                        <b>NOTES</b>
                                        <div>{data.invoice.BillAddressNote}</div>
                                    </div>
                                    <div>
                                        <div className="invoice-details">
                                            <div className="invoice-label">SUBTOTAL</div>
                                            <div className="invoice-value">${data.invoice.Subtotal}</div>

                                            <div className="invoice-label">VAT</div>
                                            <div className="invoice-value">{data.invoice.SalesTaxTotal}</div>

                                            <div className="invoice-label">TOTAL</div>
                                            <div className="invoice-value">${data.invoice.Subtotal}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
