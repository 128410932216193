import React, {  useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useMutation, useQueryClient } from 'react-query';
import { updateUserAddress } from '../Api';
import { useAuth } from '../lib/auth';
import { AddressFormNew } from './addressFormNew';

export const AddressDisplay = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [editAddress, setEditAddress] = useState(false);

    const { user } = useAuth();
    
    let defaultValues = {
        address: user.address? user.address : "",
        addressAdditional: user.addressAdditional? user.addressAdditional : "",
        state: user.addressState? user.addressState : "",
        zipCode: user.addressPostalCode? user.addressPostalCode : "",
        city: user.addressCity? user.addressCity: ""
    }
    const addressDisplayStatic = (
        <div className="">
            <p className='text-lg'>
                {defaultValues.address}<br/>
                {defaultValues.addressAdditional? defaultValues.addressAdditional + '<br/>': ""}
                {defaultValues.city}, {defaultValues.state}<br/>
                {defaultValues.zipCode}
            </p>
            {props.allowEdit ? <Button label="Edit" onClick={type => setEditAddress(true)} /> : ""}
        </div>
    );

    return (
        <AddressFormNew user={user} />
    );
}
        