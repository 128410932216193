import React, { useState, useEffect, useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { getUserCart, getUserCartCount } from './Api';
import LoadingScreen from './component/loadingScreen';
import { useQuery } from 'react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import {useHistory} from 'react-router-dom';


export const Cart = (props) => {

    const { isLoading, error, data, isFetching } = useQuery("cartData", getUserCartCount);

    const history = useHistory();
    

    const onClick = () => {
        history.push('/cart/view');
    }

    return (
            <i onClick={onClick} className="pi pi-shopping-cart p-overlay-badge" label="Cart" style={{ fontSize: '1.5rem'}}>
                 <Badge value={data ? data : 0}></Badge>
            </i>
    );
}