import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Cart } from './Cart';

export const AppTopbar = (props) => {

    return (
        <Menubar
            model={props.items}
            end={<Cart />}
        />
    );

};