import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { postMedvantageApi } from "../Api";
import { AddressFormNew } from "../component/addressFormNew";
import { useAuth } from "../lib/auth";


export const UpdatePasswordForm = (props) => {

    const [showErrorMessagePassword, setShowErrorMessagePassword] = useState(false);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);

    const passwordDefaultValues = {
        password: '',
        newPassword: '',
        newPasswordConfirmation: ''
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues: passwordDefaultValues });  

    const onSubmitPassword = async (data) => {
        setLoading(true);
        setFormData(data);
        if (data.newPasswordConfirmation === data.newPassword) {
            let postData = {
                password: data.password,
                newPassword: data.newPassword,
                newPasswordConfirmation: data.newPasswordConfirmation
            };
            try {
                await postMedvantageApi('user/update/password', postData);
            } catch (error) {
                console.log(error);
            }
            reset();
        } else {
            setShowErrorMessagePassword(true);
            reset();
        }
        setLoading(false);
    };

    const getFormErrorMessagePassword = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );



    return (

        <form onSubmit={handleSubmit(onSubmitPassword)} className="p-fluid justify-items-center">
            <div className="field">
                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Current Password*</label>
                <Controller name="password" control={control} rules={{ required: 'Current password is required.' }} render={({ field, fieldState }) => (
                    <Password id={field.name} {...field} toggleMask={false}  feedback={false} className={classNames({ 'p-invalid': fieldState.invalid })}  />
                )} />
                {getFormErrorMessagePassword('password')}
            </div>
            <div className="field">
                <label htmlFor="newPassword" className={classNames({ 'p-error': errors.newPassword })}>New Password*</label>
                <Controller name="newPassword" control={control} rules={{ required: 'New password is required.' }} render={({ field, fieldState }) => (
                    <Password id={field.name} {...field} feedback={true} className={classNames({ 'p-invalid': fieldState.invalid })} header={passwordHeader} footer={passwordFooter} />
                )} />
                {getFormErrorMessagePassword('newPassword')}
            </div>
            <div className="field">
                <label htmlFor="newPasswordConfirmation" className={classNames({ 'p-error': errors.newPasswordConfirmation })}>Confirm Password*</label>
                <Controller name="newPasswordConfirmation" control={control} rules={{ required: 'Password confirmation is required.' }} render={({ field, fieldState }) => (
                    <Password id={field.name} {...field} feedback={false} toggleMask={false} className={classNames({ 'p-invalid': fieldState.invalid })}  />
                )} />
                {getFormErrorMessagePassword('newPasswordConfirmation')}
            </div>
            <div className="grid">
                <div className="col-2 col-offset-10 ">
                    <Button type="update" label="Update" loading={loading} className="mt-2" />
                </div>
            </div>
        </form>

    )

}