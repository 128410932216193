import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { postMedvantageApi } from "../Api";
import { AddressFormNew } from "../component/addressFormNew";
import { UpdatePasswordForm } from "../component/updatePasswordForm";
import { useAuth } from "../lib/auth";


export const AccountSettings = () => {

    const { user } = useAuth();
    const userMessages = useRef(null);
    
    return (
        <div className="grid">
            <div className="col-10 col-offset-1">
                <Card title="Account Settings">
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <InputText id="email" type="text" className="inputfield w-full" disabled={true} defaultValue={user.email} />
                    </div>
                    <div className="field">
                        <label htmlFor="associatedCompany">Associated Company</label>
                        <InputText id="associatedCompany" type="text" className="inputfield w-full" disabled={true} defaultValue={user.accountRef} />
                    </div>
                    <Divider/>
                    <AddressFormNew user={user}/>
                </Card>
                <Divider/>
                <Card title="Update Password">
                    <UpdatePasswordForm />
                </Card>
            </div>
        </div>
    );
}
