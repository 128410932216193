import React from 'react';
import { useQuery } from 'react-query';
import { getUserInvoices, getUserOrders } from '../Api';
import LoadingScreen from '../component/loadingScreen';
import { RecentOrders } from './RecentOrders';

export const MyOrders = () => {

    const { isLoading: isLoadingOrdersList, error: ordersListError, data: ordersListData, isFetching: isFetchingOrdersList } = useQuery("onlineOrderData", getUserOrders);

    return (
        <div>
            <RecentOrders data={ordersListData} isLoading={isLoadingOrdersList} title={'Orders'} />
        </div>
    );
}
