import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingScreen = () => {
    return (
        <div className="d-flexjc-center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height:'100vh'}}>
            <ProgressSpinner />
        </div>
    );
}

export default LoadingScreen;