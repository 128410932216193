import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from 'react-query';
import { updateUserAddress } from "../Api";


export const AddressFormNew = (props) => {

    const user = props.user;
    const [selectState, setSelectState] = useState(user.addressState);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);

    const statesList = [
        {
          "label": "Alabama",
          "value": "AL"
        },
        {
          "label": "Alaska",
          "value": "AK"
        },
        {
          "label": "Arizona",
          "value": "AZ"
        },
        {
          "label": "Arkansas",
          "value": "AR"
        },
        {
          "label": "California",
          "value": "CA"
        },
        {
          "label": "Colorado",
          "value": "CO"
        },
        {
          "label": "Connecticut",
          "value": "CT"
        },
        {
          "label": "Delaware",
          "value": "DE"
        },
        {
          "label": "Florida",
          "value": "FL"
        },
        {
          "label": "Georgia",
          "value": "GA"
        },
        {
          "label": "Hawaii",
          "value": "HI"
        },
        {
          "label": "Idaho",
          "value": "ID"
        },
        {
          "label": "Illinois",
          "value": "IL"
        },
        {
          "label": "Indiana",
          "value": "IN"
        },
        {
          "label": "Iowa",
          "value": "IA"
        },
        {
          "label": "Kansas",
          "value": "KS"
        },
        {
          "label": "Kentucky",
          "value": "KY"
        },
        {
          "label": "Louisiana",
          "value": "LA"
        },
        {
          "label": "Maine",
          "value": "ME"
        },
        {
          "label": "Maryland",
          "value": "MD"
        },
        {
          "label": "Massachusetts",
          "value": "MA"
        },
        {
          "label": "Michigan",
          "value": "MI"
        },
        {
          "label": "Minnesota",
          "value": "MN"
        },
        {
          "label": "Mississippi",
          "value": "MS"
        },
        {
          "label": "Missouri",
          "value": "MO"
        },
        {
          "label": "Montana",
          "value": "MT"
        },
        {
          "label": "Nebraska",
          "value": "NE"
        },
        {
          "label": "Nevada",
          "value": "NV"
        },
        {
          "label": "New Hampshire",
          "value": "NH"
        },
        {
          "label": "New Jersey",
          "value": "NJ"
        },
        {
          "label": "New Mexico",
          "value": "NM"
        },
        {
          "label": "New York",
          "value": "NY"
        },
        {
          "label": "North Carolina",
          "value": "NC"
        },
        {
          "label": "North Dakota",
          "value": "ND"
        },
        {
          "label": "Ohio",
          "value": "OH"
        },
        {
          "label": "Oklahoma",
          "value": "OK"
        },
        {
          "label": "Oregon",
          "value": "OR"
        },
        {
          "label": "Pennsylvania",
          "value": "PA"
        },
        {
          "label": "Rhode Island",
          "value": "RI"
        },
        {
          "label": "South Carolina",
          "value": "SC"
        },
        {
          "label": "South Dakota",
          "value": "SD"
        },
        {
          "label": "Tennessee",
          "value": "TN"
        },
        {
          "label": "Texas",
          "value": "TX"
        },
        {
          "label": "Utah",
          "value": "UT"
        },
        {
          "label": "Vermont",
          "value": "VT"
        },
        {
          "label": "Virginia",
          "value": "VA"
        },
        {
          "label": "Washington",
          "value": "WA"
        },
        {
          "label": "West Virginia",
          "value": "WV"
        },
        {
          "label": "Wisconsin",
          "value": "WI"
        },
        {
          "label": "Wyoming",
          "value": "WY"
        }
    ];

    let defaultValuesAddress = {
        address: user.address ? user.address : "",
        addressAdditional: user.addressAdditional ? user.addressAdditional : "",
        state: user.addressState ? user.addressState : "",
        city: user.addressCity ? user.addressCity : "",
        zipCode: user.addressPostalCode ? user.addressPostalCode : ""
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues: defaultValuesAddress });  
    const onSubmitAddress = async (data, event) => {
        await setLoading(true);
        setFormData(data);
        await updateMutation.mutate(data);
        
    };

    const getFormErrorMessageAddress = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    
    const queryClient = useQueryClient()

    const updateMutation = useMutation(userAddressData => updateUserAddress(userAddressData), {
        onSuccess: async updateUserAddressDisplay => {
            defaultValuesAddress = formData;
            await queryClient.invalidateQueries('auth-user');
            await setLoading(false);
        },
    });



    return (
        <form id="addressForm" onSubmit={handleSubmit(onSubmitAddress)}>
            <div className="field">
                <label htmlFor="address">Address*</label>
                <Controller name="address" control={control} rules={{ required: 'Address is required.' }} render={({ field, fieldState }) => (
                    <InputText id={field.address} {...field} autoFocus className={"inputfield w-full " + classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
                {getFormErrorMessageAddress('address')}
            </div>
            <div className="field">
                <label htmlFor="address">Address Line 2</label>
                <Controller name="addressAdditional" control={control} render={({ field, fieldState }) => (
                    <InputText id={field.addressAdditional} {...field} autoFocus className="inputfield w-full" />
                )} />
            </div>
            <div className="field grid">
                <div className="col field">
                    <label htmlFor="city" className={classNames({ 'p-error': errors.city })}>City*</label>
                    <Controller name="city" control={control}
                        rules={{ required: 'City is required.'}}
                        render={({ field, fieldState }) => (
                            <InputText id={field.city} {...field} className={"inputfield w-full " + classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessageAddress('city')}
                </div>
                <div className="col field">
                    <label htmlFor="state" className={classNames({ 'p-error': errors.state })}>State*</label>
                    <Controller name="state" control={control} rules={{ required: 'State is required.' }} render={({ field, fieldState }) => (
                        <Dropdown id={field.state} {...field}  className={"inputfield w-full " + classNames({ 'p-invalid': fieldState.invalid })} type="text" value={selectState} options={statesList} onChange={(e) => setSelectState(e.value)} placeholder="Select a State" />
                    )} />
                    {getFormErrorMessageAddress('state')}
                </div>
                <div className="col field">
                    <label htmlFor="zipCode" className={classNames({ 'p-error': errors.zipCode })}>Zip Code*</label>
                    <Controller name="zipCode" control={control} rules={{ required: 'Zip code is required.' }}render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={"inputfield w-full " + classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessageAddress('zipCode')}
                </div>
            </div>
            <div className="grid">
                <div className="col-2 col-offset-10 p-fluid">
                    <Button type="update" label="Update" loading={loading} className="mt-2" />
                </div>
            </div>
    </form>
    );
 
}