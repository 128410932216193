import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer bottom-auto">
            <div className="footer-logo-container">
                <span className="app-name">MedVantage</span>
            </div>
            <span className="copyright">&#169; MedVantage Online Ordering</span>
        </div>
    );
}

export default AppFooter;
