import { initReactQueryAuth } from "react-query-auth";
import {
  getUserProfile,
  registerWithEmailAndPassword,
  loginWithEmailAndPassword,
  User
} from "../Api";
import { storage } from "../utils";
import LoadingScreen from "../component/loadingScreen";
import { Login } from "../screens/Login";

export async function handleUserResponse(data: any) {
  const { access_token, user } = data;
  storage.setToken(access_token);
  return user;
}

async function loadUser() {
  let user = null;

  if (storage.getToken()) {
    try {
      const data = await getUserProfile();
      user = data;
    } catch (e) {
      console.log(e);
    }
  }
  return user;
}

async function loginFn(data: any) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data: any) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  await storage.clearToken();
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent: () => LoadingScreen(),
  ErrorComponent: () => Login()
};

const { AuthProvider, useAuth } = initReactQueryAuth<User>(authConfig);

export { AuthProvider, useAuth };
