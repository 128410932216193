import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { Login } from './screens/Login';
import { Error } from './screens/Error';
import { NotFound } from './screens/NotFound';
import { Access } from './screens/Access';
import { ActivateAccount } from './screens/public/ActivateAccount';
import { ResetPassword } from './screens/public/ResetPassword';


const AppWrapper = () => {

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    let pathName = location.pathname;

    if (pathName === "/login") {
        return <Route path="/login" component={Login} />
    } else if (pathName === "/error") {
        return <Route path="/error" component={Error} />
    } else if (pathName === "/notfound") {
        return <Route path="/notfound" component={NotFound} />
    } else if (pathName === "/access") {
        return <Route path="/access" component={Access} />
    } else if (pathName.includes("/account/activate/")) {
        return <Route path="/account/activate/:id" component={ActivateAccount} />
    } else if (pathName.includes("/reset/password")) {
        return <Route path="/reset/password" component={ResetPassword} />
    } else {
        return <App />;
    }
}

export default withRouter(AppWrapper);
