export const storage = {
    getToken: () => {
      if(window.localStorage.getItem('token') == null || window.localStorage.getItem('token') === 'undefined') {
      } else {
        return JSON.parse(window.localStorage.getItem("token")!)
      }
    },
    setToken: (token: any) =>
      window.localStorage.setItem("token", JSON.stringify(token)),
    clearToken: () => window.localStorage.removeItem("token")
  };