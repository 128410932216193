import React, { useEffect, useRef, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { finalizeUserCart, getUserCart, getUserCartStats} from '../Api';
import { Button } from 'primereact/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LoadingScreen from '../component/loadingScreen';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { useAuth } from '../lib/auth';
import { BillingForm } from '../component/billingForm';
import { AddressDisplay } from '../component/addressDisplay';
import { useRouter } from '../component/useRouter';
import { Message } from 'primereact/message';
import { useSelector, useDispatch } from 'react-redux';
import { setTrue, setFalse } from '../app/globalState';


export const CheckoutPage = () => {

    const { user } = useAuth();
    const queryClient = useQueryClient()

    const { isLoading, error, data, isFetching } = useQuery("cartDataList", getUserCart);

    const { isLoading: isLoadingCartStats, error: errorCartStats, data: dataCartStats, isFetching: isFetchingCartStats } = useQuery("cartDataStats", getUserCartStats);
    const dispatch = useDispatch()



    const recentProductsHeader = (
        <div className="card-header">
            <h4>Cart</h4>
        </div>
    );

    const router = useRouter();

    const onClickFinalizeOrder = () => {
        finalizeUserCart().then((result) => {
            dispatch(setTrue);
            queryClient.invalidateQueries('cartData');
            console.log(result);
            if (result === 401) {
                console.log(result);
            } else {
                router.push("/orders/view/" + result.id);
            }
        }).catch((err) => {
            console.log(err);
        });
    };
    
    if (isLoading) return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    < LoadingScreen />
                </div>
            </div>
        </div>
    );

    if (isFetching) return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    < LoadingScreen />
                </div>
            </div>
        </div>
    );

    if (error) return "Error";

    const renderCards = (data) => {
        return (
            <div className="col-12">
                {data.map((line) => 
                    <div key={line.ReferenceID}>
                        <div className="flex" style={{position: 'relative'}}>
                            <div className="col-2"><img className="product-img-thumbnail" src={line.Product.ImageUrl.replace('.png', '_thumbnail250.png')} alt={'product'}></img></div>
                            <div className="col-10">
                                <div className="flex">
                                    <div className="col-7">
                                        <div className="product-name">{line.Product.FullName}</div>
                                        <div>{line.Product.ItemDesc}</div>
                                    </div>
                                    <div className="col-2" style={{'paddingBottom': '.5rem', 'textAlign': '-webkit-center'}}>
                                        <h3>Qty: {line.Qty}</h3>
                                    </div>
                                    <div className="col-3" style={{'paddingBottom': '.5rem', 'textAlign': '-webkit-center'}}>
                                        <h3>${line.LineSubtotal}</h3>
                                    </div>
                                </div>
                                <div>
                                    <div style={{position: 'absolute', bottom: 0}}><i className={"pi pi-clock product-shipping-icon"}></i><span className="shipping-info">{line.deliveryEstimate ? 'Ships ' + line.deliveryEstimate : "Shipping estimate unavailable"}</span></div>
                                </div>
                            </div>
                        </div>
                        <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                    </div>
                )}
            </div>
        );
    }

    return (

            <div className="grid">
                <div className="col-9">
                    <Card>
                        <Message className="fluid" style={{width: '100%' }} severity="info" text={dataCartStats ? dataCartStats.deliveryEstimate : "" } />
                    </Card>
                    <Divider></Divider>
                    <Card>
                        <h3>Shipping Address</h3>
                        <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                        <AddressDisplay allowEdit={true} ></AddressDisplay>
                    </Card>
                    <Divider></Divider>
                    <Card>
                        <h3>Billing Information</h3>
                        <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                        <BillingForm user={user}></BillingForm>
                    </Card>
                    <Divider></Divider>
                    <Card>
                        <div className="card-header">
                            <h3>Review Items</h3>
                        </div>
                        <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                        {data ? renderCards(data) : < LoadingScreen />}
                    </Card>
                </div>
                <div className="col-3">
                {dataCartStats? 
                    <Card>
                        <div className="card-header">
                            <div className="">
                                <Button onClick={onClickFinalizeOrder} label="Place Order" form="orderInformation"></Button>
                            </div>
                        </div>
                        <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                        
                        <p className='text-2xl'>Order Summary</p>
                        <div className="flex">
                            <div className="flex-none">
                                <p className={"text-lg"}>Items:</p>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-right">${dataCartStats.subtotal}</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <p className={"text-lg"}>Shipping:</p>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-right">${dataCartStats.shippingCost}</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <p className={"text-lg"}>Before tax:</p>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-right">${dataCartStats.totalBeforeTax}</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <p className={"text-lg"}>Tax:</p>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-right">${dataCartStats.tax}</p>
                            </div>
                        </div>

                        <Divider style={{'borderBottom': '.1rem solid lightgray', 'bottomMargin': '1rem'}}/>
                        <div className="card-footer flex">
                            <div className="flex-none">
                                <p className={"text-lg"}>Order total:</p>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-right">${dataCartStats.totalIncludingTax}</p>
                            </div>
                        </div>
                    </Card>
                : <LoadingScreen/> }
                </div>
            </div>
    );
}