import { Dashboard } from "./screens/Home";
import React, { useState, useEffect, useRef } from 'react';
import AppFooter from './AppFooter';
import { Route, useHistory } from "react-router-dom";
import { AppTopbar } from "./AppTopbar";
import { AppProfile } from './AppProfile';
import AppMenu from "./AppMenu";
import { Invoice } from "./screens/Invoice";
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { CreateOrder } from "./screens/CreateOrder";
import { RecentProducts } from "./screens/RecentProducts";
import { MyCart } from "./screens/MyCart";
import { CheckoutPage } from "./screens/CheckoutPage";
import { MyOrders } from "./screens/MyOrders";
import { OrderConfirmation } from "./screens/OrderConfirmation";
import { useAuth } from "./lib/auth";
import { AccountSettings } from "./screens/AccountSettings";
import { OrderView } from "./screens/OrderView";

export default function BaseCode() {

    const logo = 'logo.svg';

    const routers = [
        { path: '/', component: RecentProducts, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }] } },
        { path: '/invoices/view/:id', component: Invoice },
        { path: '/products/recent', component: RecentProducts},
        { path: '/orders/confirmation/:id', component: OrderConfirmation},
        { path: '/cart/view', component: MyCart},
        { path: '/orders/view/:id', component: OrderView},
        { path: '/order/create', component: CreateOrder },
        { path: '/orders/all', component: MyOrders},
        { path: '/checkout', component: CheckoutPage},
        { path: '/account/settings', component: AccountSettings}   
    ];

    const history = useHistory();
    const toast = useRef(null);

    const { logout } = useAuth();

    const menu = [
        { label: 'Products',  command: () => { history.push('/'); } },
        { label: 'Orders',  command: () => { history.push('/orders/all'); } },
        { label: 'Settings',  command: () => { history.push('/account/settings'); } },
        { label: 'Sign out',  command: () => { logout(); history.push('/'); } }
    ];

    return (
        <>
            <AppTopbar items={menu} />
            <div className="layout-content">
                {routers.map((router, index) => {
                    if (router.exact) {
                        return <Route key={`router${index}`} path={router.path} exact component={router.component} />;
                    }
                    return <Route key={`router${index}`} path={router.path} component={router.component} />;
                })}
            </div>
            <AppFooter />
            <Toast ref={toast} />
        </>
    );

}
