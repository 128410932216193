import { Card } from 'primereact/card';
import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUserOrderDetails } from '../Api';

export const OrderConfirmation = (props) => {

    const { id } = useParams();

    const { isLoading, error, data, isFetching }  = useQuery(['orderDetails', id], () => getUserOrderDetails(id));


    if (isLoading) return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    < uk-spinner />
                </div>
            </div>
        </div>
    );

    return (
        <div className="grid">
            <div className='col-12 md:col-10 md:offset-col-1'>
                <Card>
                    <h2 style={{marginBottom: 0}}>Thanks for your order!</h2>
                    <p>An email confirmation will be sent after the order has been approved.</p>
                    <p>Order number: {data.id}</p>
                    <p>Order total: {data.GrandTotal}</p>
                </Card>
            </div>

        </div>
    );
}
