import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, { useState, useEffect, useRef } from 'react';
import { getUserOrderDetails, sendActivationPassword } from '../../Api';
import { useForm, Controller } from 'react-hook-form';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog'; 
import { Divider } from 'primereact/divider';
import { useHistory, useParams } from "react-router-dom";

export const ActivateAccount = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const history = useHistory();
    const defaultValues = {
        password: '',
        passwordConfirmation: ''
    }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    const { id } = useParams();

    if (id.length < 1) {
        history.push('/error');
    }

    const onSubmit = (data) => {
        setIsLoading(true);
        setFormData(data);
        if (data.passwordConfirmation === data.password) {
            let postData = {
                activationString: id,
                password: data.password
            };
            console.log(postData);
            sendActivationPassword(postData).then( () => {
                setShowMessage(true);
                history.push('/');
            }).catch( (error) => {
                setIsLoading(false);
                setShowMessage(true);
                reset();
            }).finally( () => history.push('/'));
        } else {
            setShowErrorMessage(true);
            reset();
        }
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    const dialogFooterError = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowErrorMessage(false)} /></div>;
    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );
    

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const cardHeader = () => {
        return (
            <div className='flex justify-content-center' style={{"marginTop":"2rem"}}>
                <h5 className='text-center'>Activate Account</h5>
            </div>
        )
    }


    return (
        <div className='form-container'>
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Activation Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your account password has been updated. 
                    </p>
                </div>
            </Dialog>
            <Dialog visible={showErrorMessage} onHide={() => setShowErrorMessage(false)} position="top" footer={dialogFooterError} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className="pi pi-ban" style={{ fontSize: '5rem', color: 'var(--red-500)' }}></i>
                    <h5>Validation Error</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your password and password confirmation do not match.
                    </p>
                </div>
            </Dialog>
            <div className="flex align-items-center justify-content-center" style={{height:"100vh"}}>
                    <Card style={{"minWidth": "400px"}} header={cardHeader}>
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid justify-items-center">
                            <div className="field">
                                <span className="p-float-label">
                                    <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                        <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} header={passwordHeader} footer={passwordFooter} />
                                    )} />
                                    <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password*</label>
                                </span>
                                {getFormErrorMessage('password')}
                            </div>
                            <div className="field">
                                <span className="p-float-label">
                                    <Controller name="passwordConfirmation" control={control} rules={{ required: 'Password confirmation is required.' }} render={({ field, fieldState }) => (
                                        <Password id={field.name} {...field} feedback={false} toggleMask={false} className={classNames({ 'p-invalid': fieldState.invalid })}  />
                                    )} />
                                    <label htmlFor="passwordConfirmation" className={classNames({ 'p-error': errors.passwordConfirmation })}>Confirm Password*</label>
                                </span>
                                {getFormErrorMessage('passwordConfirmation')}
                            </div>
                            <Button type="submit" label="Submit" loading={isLoading} className="mt-2" />
                        </form>
                    </Card>
            </div>
        </div>
    );
}
