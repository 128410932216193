import { storage } from "./utils";

interface AuthResponse {
  user: User;
  jwt: string;
}

export interface User {
  id: string;
  email: string;
  name?: string;
}
let API_URL = 'https://api.medvantage.org:3001';

export async function handleApiResponse(response: { json: () => any; ok: any; }) {
  const data = await response.json();
  if (response.ok) {
    return data;
  } else {
    return Promise.reject(data);
  }
}

export async function medvantageApi(endpoint: string) {
  return await fetch(`${API_URL}/${endpoint}`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserProfile() {
  return await fetch(`${API_URL}/profile`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserOrders() {
  return await fetch(`${API_URL}/orders/all`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserInvoices() {
  return await fetch(`${API_URL}/invoices/list`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserCart() {
  return await fetch(`${API_URL}/cart/list`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserCartCount() {
  return await fetch(`${API_URL}/cart/count`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserCartSubtotal() {
  return await fetch(`${API_URL}/cart/subtotal`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserCartStats() {
  return await fetch(`${API_URL}/cart/checkoutStats`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserProducts() {
  return await fetch(`${API_URL}/products/list`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function updateUserCartData(data: any) {

  return await fetch(`${API_URL}/cart/update`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken(),
      "content-type": "application/json"
    },
    body: JSON.stringify({"ReferenceID": data.ReferenceID, "Qty": data.Qty})
  }).then(handleApiResponse);
}

export async function updateUserAddress(data: any) {
  return await fetch(`${API_URL}/user/update/address`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken(),
      "content-type": "application/json"
    },
    body: JSON.stringify({"address": data.address, "addressAdditional": data.addressAdditional, "addressState": data.state, "addressPostalCode": data.zipCode, "addressCity": data.city})
  }).then(handleApiResponse);
}

export async function updateUserOrder(data: any) {
  return await fetch(`${API_URL}/orders/update`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken(),
      "content-type": "application/json"
    },
    body: JSON.stringify({"orderContactName": data.contactName, "orderContactEmail": data.email, "orderNotes": data.notes})
  }).then(handleApiResponse);
}

export async function getOrderInformation() {
  return await fetch(`${API_URL}/orders/current`, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function addUserCartData(data: any) {

  return await fetch(`${API_URL}/cart/add`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken(),
      "content-type": "application/json"
    },
    body: JSON.stringify({"ReferenceID": data.ReferenceID, "Qty": data.Qty})
  }).then(handleApiResponse);
}

export async function sendActivationPassword(data: any) {

  return await fetch(`${API_URL}/user/activate/`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken(),
      "content-type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(handleApiResponse);
}

export async function postMedvantageApi (path: string, data: any) {

  return await fetch(`${API_URL}/${path}`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken(),
      "content-type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(handleApiResponse);
}

export async function finalizeUserCart() {

  return await fetch(`${API_URL}/orders/finalize`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);

}

export async function deleteUserCartItem(data: any) {
  return await fetch(`${API_URL}/cart/delete`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + storage.getToken(),
      "content-type": "application/json"
    },
    body: JSON.stringify({"ReferenceID": data})
  }).then(handleApiResponse);
}

export async function getUserInvoiceDetails(id: string) {
  return await fetch(`${API_URL}/invoices/${id}`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function getUserOrderDetails(id: string) {
  return await fetch(`${API_URL}/orders/view/${id}`, {
    headers: {
      "Authorization": 'Bearer ' + storage.getToken()
    }
  }).then(handleApiResponse);
}

export async function loginWithEmailAndPassword(data: any): Promise<AuthResponse> {
  return window
    .fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {"content-type": "application/json"},
      body: JSON.stringify(data)
    })
    .then(handleApiResponse);
}

export async function registerWithEmailAndPassword(
  data: any
): Promise<AuthResponse> {
  return window
    .fetch(`${API_URL}/auth/register`, {
      method: "POST",
      body: JSON.stringify(data)
    })
    .then(handleApiResponse);
}
