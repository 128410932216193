import React, { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { getUserProducts, updateUserCartData } from '../Api';
import { Button } from 'primereact/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LoadingScreen from '../component/loadingScreen';
import { InputNumber } from 'primereact/inputnumber';
import { QuantityForm } from './QuantityForm';

export const RecentProducts = () => {
    const { isLoading, error, data, isFetching } = useQuery("productData", getUserProducts);

    const renderListItemEdit = (data) => {
        let lineObj = {"ReferenceID": data.ref, "Qty": 1};

        return (
            <div className="col-12">
                <div className="product-list-item">
                    <img className="product-img-thumbnail" src={data.imageThumbnail} alt={'product'}></img>
                    <div className="product-list-detail">
                        <div style={{'paddingBottom': '.5rem', 'paddingTop': '.2rem'}}><span className={`product-badge status-${data.stock_status}`}>{data.stock_status}</span></div>
                        <div className="product-name">{data.name}</div>
                        <div className="product-description">{data.description}</div>
                        <div style={{position: 'absolute', bottom: 0}}><i className={"pi pi-clock product-shipping-icon"}></i><span className="shipping-info">{data.deliveryEstimate ? "Ships " + data.deliveryEstimate : "Shipping estimate unavailable"}</span></div>
                    </div>
                    <div className="product-list-action">
                        <h4>${data.price}</h4>
                        <QuantityForm line={lineObj} type={"add"}></QuantityForm>
                    </div>
                </div>
            </div>
        );
    }

    const recentProductsHeader = (
        <div className="card-header">
            <h4>Recent Products</h4>
        </div>
    );

    if (error) return "Error";

    return (
        <div className="availableProducts">
            <div className="card">
                {isLoading? < LoadingScreen /> : <DataView value={data} itemTemplate={renderListItemEdit} header={recentProductsHeader} paginator rows={5} />}
            </div>
        </div>
    );
}